@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;400;700;900&family=Lato:wght@900&family=Montserrat:wght@300;400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  font-family: 'Kanit', sans-serif;
  font-family: 'Lato', sans-serif;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
  font-size: 62%;
}
